<template>
  <v-row>
    <!--v-col>
      <p class="text-center text-body-2">
        {{ requests.length }} von {{ maximumRequests }} Wünschen ausgewählt
      </p>
    </v-col -->
    <v-fade-transition leave-absolute>
      <v-col
        v-if="loading"
        key="skeleton"
        cols="12"
        class="mb-0"
      >
        <v-card
          :elevation="1"
          data-cy="addedPlan"
          :class="'mb-0 sectionbordersecondary'"
        >
          <v-skeleton-loader type="list-item-two-line" />
        </v-card>
      </v-col>
    </v-fade-transition>
    <v-col v-if="!readonly && requests.length < maximumRequests && !loading">
      <v-menu
        v-if="facility && !loading"
        v-model="menu"
        :close-on-content-click="false"
      >
        <template #activator="{ props }">
          <v-card
            data-cy="requestedRotations"
            class="add-link"
            v-bind="props"
            color="transparent"
            elevation="0"
          >
            <v-card-text class="text-center text-primary text-body-1">
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="text-body-1 pt-2 pl-2">Wunsch hinzufügen</span>
            </v-card-text>
          </v-card>
        </template>
        <v-card>
          <v-list
            class="field-dropdown"
            rounded
          >
            <template v-if="getPremiumFeatures().includes('INTERNAL_ROTATION')">
              <v-list-group
                v-for="rotationType in Object.keys(groupByProperty(units, 'rotation_types', true))"
                :key="rotationType"
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    :title="rotationType"
                    data-cy="rotationType"
                  >
                  </v-list-item>
                </template>

                <v-list-item
                  v-for="unit in groupByProperty(units, 'rotation_types', true)[rotationType]"
                  :key="unit.id"
                  :title="formatParts([unit.short_name, unit.long_name])"
                  data-cy="rotationUnit"
                  @click="createRequest(rotationType, unit.id)"
                >
                </v-list-item>
              </v-list-group>
              <v-list-group v-if="facilities.length">
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    title="Rotation"
                    data-cy="rotationType"
                  >
                  </v-list-item>
                </template>
                <template
                  v-for="(externalFacility, i) in filteredFacilities"
                  :key="externalFacility.id"
                >
                  <v-list-item
                    class="py-2"
                    data-cy="facility"
                    @click="createExternalRequest(externalFacility.id)"
                  >
                    <v-list-item-subtitle>
                      {{ formatFacilityHierarchy(externalFacility) }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ externalFacility.name }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="filteredFacilities && i < filteredFacilities.length - 1" />
                </template>
              </v-list-group>
            </template>
            <template v-else>
              <template
                v-for="(externalFacility, i) in filteredFacilities"
                :key="externalFacility.id"
              >
                <v-list-item
                  class="py-2"
                  data-cy="facility"
                  @click="createExternalRequest(externalFacility.id)"
                >
                  <v-list-item-subtitle>
                    {{ formatFacilityHierarchy(externalFacility) }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ externalFacility.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider v-if="filteredFacilities && i < filteredFacilities.length - 1" />
              </template>
            </template>
          </v-list>
        </v-card>
      </v-menu>
      <v-alert
        v-else
        border="start"
        type="error"
        color="error"
        elevation="2"
      >
        <div>Bitte geben Sie Ihre Klinik in den Einstellungen an.</div>
      </v-alert>
    </v-col>
    <v-col
      v-for="(request, r) in requests"
      :key="r"
      cols="12"
    >
      <v-card
        hover
        elevation="0"
        color="primary"
        variant="outlined"
        @click="selectedRequest = selectedRequest === request.id ? null : request.id"
      >
        <v-card-text v-if="request.unit">
          <h2 class="text-body-1 text-center text-primary">
            <strong v-if="request.rotation_type">{{ request.rotation_type }}</strong>
            <br />
            {{ formatParts([request.unit.short_name, request.unit.long_name]) }}
          </h2>
        </v-card-text>
        <v-card-text v-if="request.facility">
          <h2 class="text-body-1 text-center text-primary">
            <strong>Rotation</strong><br />
            <span class="text-grey-darken-2">{{ formatFacilityHierarchy(request.facility) }}</span>
            <br v-if="formatFacilityHierarchy(request.facility)" />
            {{ request.facility.name }}
          </h2>
        </v-card-text>
        <v-overlay
          v-if="!readonly"
          class="align-center justify-center"
          persistent
          scrim="#aaa"
          :model-value="selectedRequest === request.id"
          contained
        >
          <v-btn
            color="error"
            rounded
            variant="flat"
            @click="openDeleteDialog()"
          >
            <v-icon start> mdi-delete </v-icon> Löschen bestätigen
          </v-btn>
        </v-overlay>
        <v-dialog
          v-model="confirmDeleteDialog"
          width="500"
          close-on-back
        >
          <v-card
            class="pt-2 pb-5 px-5"
            append-icon="$close"
          >
            <template #append>
              <v-btn
                icon="$close"
                variant="text"
                data-cy="closeConfirmDeleteDialog"
                @click="confirmDeleteDialog = false"
              ></v-btn>
            </template>
            <v-card-item class="pt-0">
              <v-row>
                <v-col>
                  <v-card-text
                    style="line-height: 150%"
                    class="text-h2 text-center pt-0 mt-0"
                  >
                    Möchten Sie diesen Eintrag wirklich löschen?
                  </v-card-text>
                </v-col>
              </v-row>
              <v-card-actions class="">
                <v-btn
                  color="primary"
                  variant="elevated"
                  rounded
                  size="large"
                  class="px-10 mx-auto"
                  :loading="deleteLoading"
                  data-cy="deleteRequests"
                  @click="deleteRequest(request.id)"
                >
                  Eintrag löschen
                </v-btn>
              </v-card-actions>
            </v-card-item>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
    <div
      v-if="readonly && !requests.length"
      class="text-grey text-subtitle-1 ml-4"
    >
      Keine Wünsche angegeben
    </div>
    <v-overlay
      v-if="preview"
      absolute
      scrim="white"
      contained
    />
  </v-row>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
    preview: {
      type: Boolean,
    },
  },
  emits: ['wish-updated'],
  data() {
    return {
      confirmDeleteDialog: false,
      menu: false,
      previewRequests: ['Endoskopie', 'Sonografie'],
      requests: [],
      selectedRequest: null,
      units: [],
      user: null,
      maximumRequests: 3,
      physician: null,
      loading: false,
      requirements: [],
      facilities: [],
      requestFields: [
        'id',
        'unit.id',
        'unit.short_name',
        'unit.long_name',
        'rotation_type',
        'facility.id',
        'facility.name',
        'facility.show_in_hierarchy',
        'facility.parent_facility.id',
        'facility.parent_facility.name',
        'facility.parent_facility.show_in_hierarchy',
        'facility.parent_facility.parent_facility.id',
        'facility.parent_facility.parent_facility.name',
        'facility.parent_facility.parent_facility.show_in_hierarchy',
        'facility.parent_facility.parent_facility.parent_facility.id',
        'facility.parent_facility.parent_facility.parent_facility.name',
        'facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
        'facility.parent_facility.parent_facility.parent_facility.parent_facility.id',
        'facility.parent_facility.parent_facility.parent_facility.parent_facility.name',
        'facility.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
      ],
    }
  },
  computed: {
    facility() {
      return this.user && this.user.facilities && this.user.facilities.length
        ? this.user.facilities[0].facility_id
        : null
    },
    filteredFacilities() {
      if (this.requirements && this.requirements.length) {
        const requirements = this.requirements.filter((req) => req.specialities.length > 1)
        if (requirements.length) {
          const specialities = requirements
            .map((requirement) => requirement.specialities.map((spec) => spec.speciality_id))
            .flat()
          return this.facilities.filter((facility) =>
            facility.specialities
              .map((s) => s.speciality_id)
              .some((specId) => specialities.includes(specId))
          )
        }
      }
      return this.facilities
    },
  },
  async mounted() {
    this.loading = true
    this.user = await this.$cms.request(
      this.$readUser(this.userId, {
        fields: ['id', 'facilities.id', 'facilities.facility_id'],
      })
    )
    if (!this.preview) {
      if (this.facility) {
        await this.$cms
          .request(
            this.$readItems('unit', {
              filter: {
                facility: {
                  _eq: this.facility,
                },
              },
              fields: ['id', 'short_name', 'long_name', 'rotation_types'],
              sort: ['short_name'],
            })
          )
          .then((response) => {
            this.units = response.map((unit) => {
              return {
                ...unit,
                name: unit.short_name + ' - ' + unit.long_name,
              }
            })
          })
        this.facilities = await this.$cms
          .request(
            this.$readItems('schedule_flow_node', {
              filter: {
                _and: [
                  {
                    schedule_flow: {
                      facility: {
                        _eq: this.facility,
                      },
                    },
                  },
                  {
                    type: {
                      _eq: 'external',
                    },
                  },
                ],
              },
              fields: [
                'id',
                'facilities.id',
                'facilities.facility_id.id',
                'facilities.facility_id.name',
                'facilities.facility_id.show_in_hierarchy',
                'facilities.facility_id.address.id',
                'facilities.facility_id.address.street',
                'facilities.facility_id.address.street_number',
                'facilities.facility_id.address.zip',
                'facilities.facility_id.address.city',
                'facilities.facility_id.address.country_code',
                'facilities.facility_id.specialities.id',
                'facilities.facility_id.specialities.speciality_id',
                'facilities.facility_id.parent_facility.id',
                'facilities.facility_id.parent_facility.name',
                'facilities.facility_id.parent_facility.show_in_hierarchy',
                'facilities.facility_id.parent_facility.parent_facility.id',
                'facilities.facility_id.parent_facility.parent_facility.name',
                'facilities.facility_id.parent_facility.parent_facility.show_in_hierarchy',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.id',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.name',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.parent_facility.id',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.parent_facility.name',
                'facilities.facility_id.parent_facility.parent_facility.parent_facility.parent_facility.show_in_hierarchy',
              ],
            })
          )
          .then((response) => {
            // get all facilities from all external blocks
            return (
              response
                .map((block) => {
                  return block.facilities.map((f) => {
                    return f.facility_id
                  })
                })
                .flat()
                // remove duplicate facilities
                .filter((value, index, self) => index === self.findIndex((f) => f.id === value.id))
            )
          })

        this.loadData()
      }
    }
    this.loading = false
  },
  methods: {
    async loadData() {
      const _and = [
        {
          user: {
            _eq: this.userId,
          },
        },
      ]
      if (this.getPremiumFeatures().includes('COMPETENCES')) {
        _and.push({
          facility: {
            _null: true,
          },
        })
      }
      await this.$cms
        .request(
          this.$readItems('schedule_request', {
            filter: { _and },
            fields: this.requestFields,
          })
        )
        .then((response) => {
          this.requests = response
        })
      this.loadGoals()
    },
    async loadGoals() {
      const endNodes = await this.$cms.request(
        this.$readItems('schedule_flow_node', {
          filter: {
            _and: [
              {
                schedule_flow: {
                  facility: {
                    users: {
                      directus_users_id: {
                        _eq: this.userId,
                      },
                    },
                  },
                },
              },
              {
                type: {
                  _eq: 'end',
                },
              },
            ],
          },
          fields: ['id'],
        })
      )
      if (endNodes.length) {
        this.$cms
          .request(
            this.$readItems('schedule_requirements', {
              filter: {
                target: {
                  'item:schedule_flow_node': {
                    id: {
                      _eq: endNodes[0].id,
                    },
                  },
                },
              },
              fields: ['id', 'minimum_months', 'specialities.id', 'specialities.speciality_id'],
            })
          )
          .then((response) => {
            this.requirements = response
          })
      }
    },
    async createRequest(rotationType, unitId) {
      const newRequest = await this.$cms.request(
        this.$createItem(
          'schedule_request',
          {
            user: this.userId,
            rotation_type: rotationType,
            unit: unitId,
          },
          {
            fields: this.requestFields,
          }
        )
      )
      this.menu = false
      this.requests.push(newRequest)
      this.$emit('wish-updated')
    },
    async createExternalRequest(facilityId) {
      const newRequest = await this.$cms.request(
        this.$createItem(
          'schedule_request',
          {
            user: this.userId,
            facility: facilityId,
          },
          {
            fields: this.requestFields,
          }
        )
      )
      this.menu = false
      this.requests.push(newRequest)
      this.$emit('wish-updated')
    },
    async deleteRequest(requestId) {
      await this.$cms.request(this.$deleteItem('schedule_request', requestId))
      this.requests = this.requests.filter((request) => request.id !== requestId)
      this.$emit('wish-updated')
      this.confirmDeleteDialog = false
    },
    openDeleteDialog() {
      this.confirmDeleteDialog = true
    },
  },
}
</script>

<style lang="scss">
.field-dropdown {
  background-color: white;
  border: 1px solid #2f523e;
}
</style>
